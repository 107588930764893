import { Suspense } from 'react'
import { createBrowserRouter, Navigate } from 'react-router-dom'
import RootLayout from './app/layout.tsx'
import GlobalError from './app/global-error.tsx'
import UnauthenticatedLayout from './app/(unauthenticated)/layout.tsx'
import LoginPage from './app/(unauthenticated)/login/page.tsx'
import CreateAccountPage from './app/(unauthenticated)/create-account/page.tsx'
import ForgotPasswordPage from './app/(unauthenticated)/forgot-password/page.tsx'
import ResetPasswordPage from './app/(unauthenticated)/reset-password/page.tsx'
import NoOrganizationLayout from './app/(dashboard)/(no-organization)/layout.tsx'
import NoOrganizationHomePage from './app/(dashboard)/(no-organization)/(home)/page.tsx'
import SupportPage from './app/(dashboard)/support/page.tsx'
import SettingsLayout from './app/(dashboard)/settings/layout.tsx'
import OrganizationLayout from './app/(dashboard)/[organization]/layout.tsx'
import OrganizationHomePage from './app/(dashboard)/[organization]/(home)/page.tsx'
import PartnersPage from './app/(dashboard)/[organization]/partners/page.tsx'
import PartnerPage from './app/(dashboard)/[organization]/partners/[partner]/page.tsx'
import CustomersPage from './app/(dashboard)/[organization]/customers/page.tsx'
import CustomerPage from './app/(dashboard)/[organization]/customers/[customer]/page.tsx'
import MembersPage from './app/(dashboard)/[organization]/team/members/page.tsx'
import InvitationsPage from './app/(dashboard)/[organization]/team/invitations/page.tsx'
import AccountPage from './app/(dashboard)/settings/account/page.tsx'
import AppearancePage from './app/(dashboard)/settings/appearance/page.tsx'
import OrganizationsPage from './app/(dashboard)/settings/organizations/page.tsx'
import LeaveOrganizationModal from './app/(dashboard)/settings/organizations/@modal/[organization]/leave/page.tsx'
import PartnersLoading from './app/(dashboard)/[organization]/partners/loading.tsx'
import PartnersError from './app/(dashboard)/[organization]/partners/error.tsx'
import NewPartnerModal from './app/(dashboard)/[organization]/partners/@modal/new/page.tsx'
import PartnerLoading from './app/(dashboard)/[organization]/partners/[partner]/loading.tsx'
import PartnerError from './app/(dashboard)/[organization]/partners/[partner]/error.tsx'
import CustomersLoading from './app/(dashboard)/[organization]/customers/loading.tsx'
import CustomersError from './app/(dashboard)/[organization]/customers/error.tsx'
import NewCustomerModal from './app/(dashboard)/[organization]/customers/@modal/new/page.tsx'
import CustomerLoading from './app/(dashboard)/[organization]/customers/[customer]/loading.tsx'
import CustomerError from './app/(dashboard)/[organization]/customers/[customer]/error.tsx'
import TeamLayout from './app/(dashboard)/[organization]/team/layout.tsx'
import MembersLoading from './app/(dashboard)/[organization]/team/members/loading.tsx'
import MembersError from './app/(dashboard)/[organization]/team/members/error.tsx'
import RemoveMemberModal from './app/(dashboard)/[organization]/team/members/@modal/[membership]/remove/page.tsx'
import ChangeMemberAccessModal from './app/(dashboard)/[organization]/team/members/@modal/[membership]/change-access/page.tsx'
import InvitationsLoading from './app/(dashboard)/[organization]/team/invitations/loading.tsx'
import InvitationsError from './app/(dashboard)/[organization]/team/invitations/error.tsx'
import NewInvitationModal from './app/(dashboard)/[organization]/team/invitations/@modal/new/page.tsx'
import CancelInvitationModal from './app/(dashboard)/[organization]/team/invitations/@modal/[invitation]/cancel/page.tsx'
import AccountLoading from './app/(dashboard)/settings/account/loading.tsx'
import AccountError from './app/(dashboard)/settings/account/error.tsx'
import AppearanceError from './app/(dashboard)/settings/appearance/error.tsx'
import AppearanceLoading from './app/(dashboard)/settings/appearance/loading.tsx'
import OrganizationsLoading from './app/(dashboard)/settings/organizations/loading.tsx'
import OrganizationsError from './app/(dashboard)/settings/organizations/error.tsx'
import VerifyEmailPage from './app/(unauthenticated)/verify-email/page.tsx'
import InvitationPage from './app/(unauthenticated)/invitations/[invitation]/page.tsx'
import InvitationInvalidPage from './app/(unauthenticated)/invitations/invalid/page.tsx'
import InvitationRejectedPage from './app/(unauthenticated)/invitations/rejected/page.tsx'
import UnauthenticatedError from './app/(unauthenticated)/error.tsx'
import NoOrganizationError from './app/(dashboard)/(no-organization)/error.tsx'
import OrganizationError from './app/(dashboard)/[organization]/error.tsx'


export const router = createBrowserRouter([
  {
    path: '/',
    element: <Suspense fallback={null}><RootLayout /></Suspense>,
    errorElement: <GlobalError />,
    children: [
      {
        element: <UnauthenticatedLayout />,
        children: [
          {
            errorElement: <UnauthenticatedError />,
            children: [
              {
                path: 'login',
                element: <LoginPage />
              },
              {
                path: 'create-account',
                element: <CreateAccountPage />
              },
              {
                path: 'forgot-password',
                element: <ForgotPasswordPage />
              },
              {
                path: 'reset-password',
                element: <ResetPasswordPage />
              },
              {
                path: 'invitations/invalid',
                element: <InvitationInvalidPage />
              },
              {
                path: 'invitations/rejected',
                element: <InvitationRejectedPage />
              },
              {
                path: 'invitations/:invitation',
                element: <InvitationPage />
              },
              {
                path: 'verify-email',
                element: <VerifyEmailPage />
              }
            ]
          }
        ]
      },
      {
        element: <NoOrganizationLayout />,
        children: [
          {
            errorElement: <NoOrganizationError />,
            children: [
              {
                index: true,
                element: <NoOrganizationHomePage />
              },
              {
                path: 'support',
                element: <SupportPage />
              },
              {
                element: <SettingsLayout />,
                children: [
                  {
                    path: 'settings/account',
                    element: <Suspense fallback={<AccountLoading />}><AccountPage /></Suspense>,
                    errorElement: <AccountError />
                  },
                  {
                    path: 'settings/appearance',
                    element: <Suspense fallback={<AppearanceLoading />}><AppearancePage /></Suspense>,
                    errorElement: <AppearanceError />
                  },
                  {
                    path: 'settings/organizations',
                    element: <Suspense fallback={<OrganizationsLoading />}><OrganizationsPage /></Suspense>,
                    errorElement: <OrganizationsError />,
                    children: [
                      {
                        path: ':organization/leave',
                        element: <LeaveOrganizationModal />
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        element: <OrganizationLayout />,
        children: [
          {
            path: ':organization',
            errorElement: <OrganizationError />,
            children: [
              {
                index: true,
                element: <OrganizationHomePage />
              },
              {
                path: 'partners',
                children: [
                  {
                    path: '',
                    element: <Suspense fallback={<PartnersLoading />}><PartnersPage /></Suspense>,
                    errorElement: <PartnersError />,
                    children: [
                      {
                        path: 'new',
                        element: <NewPartnerModal />
                      }
                    ]
                  },
                  {
                    path: ':partner',
                    element: <Suspense fallback={<PartnerLoading />}><PartnerPage /></Suspense>,
                    errorElement: <PartnerError />
                  }
                ]
              },
              {
                path: 'customers',
                children: [
                  {
                    path: '',
                    element: <Suspense fallback={<CustomersLoading />}><CustomersPage /></Suspense>,
                    errorElement: <CustomersError />,
                    children: [
                      {
                        path: 'new',
                        element: <NewCustomerModal />
                      }
                    ]
                  },
                  {
                    path: ':customer',
                    element: <Suspense fallback={<CustomerLoading />}><CustomerPage /></Suspense>,
                    errorElement: <CustomerError />
                  }
                ]
              },
              {
                element: <TeamLayout />,
                children: [
                  {
                    path: 'team/members',
                    element: <Suspense fallback={<MembersLoading />}><MembersPage /></Suspense>,
                    errorElement: <MembersError />,
                    children: [
                      {
                        path: ':membership/remove',
                        element: <RemoveMemberModal />
                      },
                      {
                        path: ':membership/change-access',
                        element: <ChangeMemberAccessModal />
                      }
                    ]
                  },
                  {
                    path: 'team/invitations',
                    element: <Suspense fallback={<InvitationsLoading />}><InvitationsPage /></Suspense>,
                    errorElement: <InvitationsError />,
                    children: [
                      {
                        path: 'new',
                        element: <NewInvitationModal />
                      },
                      {
                        path: ':invitation/cancel',
                        element: <CancelInvitationModal />
                      }
                    ]
                  }
                ]
              },
              {
                path: 'support',
                element: <SupportPage />
              },
              {
                element: <SettingsLayout />,
                children: [
                  {
                    path: 'settings/account',
                    element: <Suspense fallback={<AccountLoading />}><AccountPage /></Suspense>,
                    errorElement: <AccountError />
                  },
                  {
                    path: 'settings/appearance',
                    element: <Suspense fallback={<AppearanceLoading />}><AppearancePage /></Suspense>,
                    errorElement: <AppearanceError />
                  },
                  {
                    path: 'settings/organizations',
                    element: <Suspense fallback={<OrganizationsLoading />}><OrganizationsPage /></Suspense>,
                    errorElement: <OrganizationsError />,
                    children: [
                      {
                        path: ':organization/leave',
                        element: <LeaveOrganizationModal />
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        path: '*',
        element: <Navigate to="/" replace />
      }
    ]
  }
], {
  future: {
    v7_relativeSplatPath: true
  }
})
