import { api } from './api'
import type { Organization } from './organization'


export type Namespace = {
  id: string
  shortId: string
  applicationId: string
  projectId: string
  name: string
  createdAt: string
  updatedAt: string
  suspended: boolean
  partnerId: string
  properties: unknown
}

export type NamespaceExpanded = Namespace & {
  partner: Organization
}

class NamespaceAPI {

  public getImpersonationURL(namespaceId: string) {
    return `${api.defaults.baseURL}/namespaces/${namespaceId}/impersonate`
  }

  public async create(data: {
    name: string
    partnerId: string
  }) {
    const result = await api.post<{ namespace: Namespace }>('/namespaces', data)

    return result.data
  }

  public async get(namespaceId: string) {
    const result = await api.get<{ namespace: NamespaceExpanded }>(`/namespaces/${namespaceId}`)

    return result.data.namespace
  }

  public async rename(namespaceId: string, name: string) {
    const result = await api.post<{ namespace: Namespace }>(
      `/namespaces/${namespaceId}/rename`,
      { name }
    )

    return result.data
  }

  public async suspend(namespaceId: string) {
    const result = await api.post<{ namespace: Namespace }>(
      `/namespaces/${namespaceId}/suspend`,
      {}
    )

    return result.data
  }

  public async recover(namespaceId: string) {
    const result = await api.post<{ namespace: Namespace }>(
      `/namespaces/${namespaceId}/recover`,
      {}
    )

    return result.data
  }

}

export const namespaceAPI = new NamespaceAPI()
