import {
  ArrowRightEndOnRectangleIcon,
  ClipboardDocumentIcon,
  EllipsisVerticalIcon
} from '@heroicons/react/20/solid'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Link, Outlet, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { namespaceAPI, type Namespace } from '~/api/namespace'
import { Head } from '~/components/head'
import { Button } from '~/components/ui/button'
import {
  DescriptionDetails,
  DescriptionList,
  DescriptionTerm
} from '~/components/ui/description-list'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '~/components/ui/dropdown-menu'
import { Separator } from '~/components/ui/separator'
import { Heading, Subheading, Text } from '~/components/ui/typography'
import { copy } from '~/lib/copy'
import { formatDateTime } from '~/lib/datetime'


export default function CustomerPage() {
  const { t, i18n } = useTranslation([ 'customers', 'general' ])
  const params = useParams() as { organization: string; customer: string }

  // Fetch the customer organization
  const { data: customer } = useSuspenseQuery({
    queryKey: [ 'organizations', params.organization, 'customers', params.customer ],
    queryFn: () => namespaceAPI.get(params.customer)
  })

  // Copy customer id and notify
  function copyCustomerId(customer: Namespace) {
    copy(customer.shortId)
    toast.info(t('view_customer.id_copied'))
  }

  return (
    <div className="flex grow flex-col gap-4 items-start">

      <Head title={customer.name} />

      <Outlet />

      <div className="flex w-full items-end justify-between gap-4">
        <Heading>{customer.name}</Heading>
        <div className="flex flex-row gap-4">
          <Link to={namespaceAPI.getImpersonationURL(customer.id)} target="_blank">
            <Button variant="ghost">
              <ArrowRightEndOnRectangleIcon className="text-tertiary" />
              {t('view_customer.impersonate')}
            </Button>
          </Link>
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Button variant="ghost" size="icon">
                <EllipsisVerticalIcon className="text-tertiary" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={() => copyCustomerId(customer)}>
                <ClipboardDocumentIcon className="text-tertiary" />
                {t('view_customer.menu.copy_id')}
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      <Separator />

      <Subheading>{t('view_customer.details')}</Subheading>

      <DescriptionList className="w-full">
        <DescriptionTerm>
          {t('fields.name')}
        </DescriptionTerm>
        <DescriptionDetails>
          {customer.name}
        </DescriptionDetails>
        <DescriptionTerm>
          {t('fields.id')}
        </DescriptionTerm>
        <DescriptionDetails className="flex gap-2 items-center">
          {customer.shortId}
        </DescriptionDetails>
        <DescriptionTerm>
          {t('fields.created_at')}
        </DescriptionTerm>
        <DescriptionDetails>
          {formatDateTime(customer.createdAt, i18n.language)}
        </DescriptionDetails>
      </DescriptionList>

      <Subheading>{t('view_customer.members')}</Subheading>

      <Text>{t('general:wip')}</Text>

      <Subheading>{t('view_customer.statistics')}</Subheading>

      <Text>{t('general:wip')}</Text>

    </div>
  )
}
